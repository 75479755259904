import { getCookie, setCookie } from "cookies-next";

import { pickKeysFromObject } from "./utilityFunctions";
import { ReadonlyURLSearchParams } from "next/navigation";
import { STATSIG_OVERRIDE_COOKIE_PREFIX } from "../constants";

function getExpiration(numOfDays: number) {
  const today = new Date();
  return new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + numOfDays,
  );
}

export function setUtmParamsCookie() {
  const urlParams = Object.fromEntries(new URLSearchParams(location.search));
  const listOfKeys = [
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "utm_content",
    "utm_cvb",
  ];
  const pickedUrlParams = pickKeysFromObject(urlParams, listOfKeys);

  if (Object.keys(pickedUrlParams).length) {
    setCookie("urlParams", pickedUrlParams, {
      // NOTE: want to set the cookie to the literal object string
      // so that it will be valid JSON when we parse it later
      encode: (val) => val,
    });
  }
}

export function setExperimentOverrideCookies(activeExperiments: object) {
  const params = new URLSearchParams(self.location.search);

  Object.keys(activeExperiments).forEach((exp) => {
    const val = params.get(exp);
    if (val) {
      setCookie(STATSIG_OVERRIDE_COOKIE_PREFIX + exp, val);
    }
  });
}

export function setExperimentOverrideCookiesApp(
  urlParams: ReadonlyURLSearchParams,
) {
  for (const [param, value] of urlParams.entries()) {
    if (param.startsWith("ss_")) {
      const key = param.slice(3);
      setCookie(STATSIG_OVERRIDE_COOKIE_PREFIX + key, value);
    }
  }
}

export function setHasSubscribedCookie() {
  const expires = getExpiration(365 * 2);
  setCookie("hasSubscribed", true, { expires });
}

export function getHasSubscribedCookie() {
  return getCookie("hasSubscribed");
}

export function setSubscribeDismissedCookie(): Date {
  const expires = getExpiration(7);
  setCookie("lastSubscribeDismissed", Date.now(), { expires });
  return expires;
}

export function dismissedSubscribeWithinLastFiveDays() {
  const lastSubscribeDismissed = getCookie("lastSubscribeDismissed");
  if (lastSubscribeDismissed) {
    const lastDate = parseInt(lastSubscribeDismissed as string);
    const diff = Date.now() - lastDate;
    return diff < 432000000; // 5 days in ms
  } else {
    return false;
  }
}

export function dismissedSubscribeWithinOneDay() {
  const lastSubscribeDismissed = getCookie("lastSubscribeDismissed");
  if (lastSubscribeDismissed) {
    const lastDate = parseInt(lastSubscribeDismissed as string);
    const diff = Date.now() - lastDate;
    return diff < 86400000; // 1 day in ms
  } else {
    return false;
  }
}

export function getUtmParams() {
  const params = getCookie("urlParams");
  return (params && JSON.parse(params as string)) || {};
}

export function setUserIDCookie(userId: string, cookieName = "userID") {
  const expires = getExpiration(365 * 2);
  setCookie(cookieName, userId, { expires });
}

export function setSubscribedStateCookies() {
  const urlParams = new URLSearchParams(location.search);

  if (urlParams.has("fp", "true")) {
    setHasSubscribedCookie();
  }
}
